
import Vue from "vue";
import API from "@/api/API";
import store from "@/store";
import tablePagination from "@/mixins/table-pagination";
import tableFilters from "@/mixins/table-filters";
import { parseArrayParams } from "@/services/helpers";
import { Dictionary } from "vue-router/types/router";

export default Vue.extend({
  name: "users",

  mixins: [tablePagination, tableFilters],

  data: () => ({
    parseArrayParams,
    headers: [] as Array<any>,
    items: [] as Array<any>,
    filter: {
      quick_filter: ""
    } as Dictionary<string | (string | null)[]>,
    showSearch: false as boolean,
    loading: false as boolean,
    queryString: "" as string,
    selects: {
      roles: [] as Array<any>
    },
    password: {
      new: "" as string,
      confirm: "" as string
    } as any,
    shownPassword: {
      new: false as boolean,
      confirm: false as boolean
    } as any,
    selectedUser: {} as any,
    changePasswordDialog: false as boolean,
    feedbackDialog: false as boolean,
    feedbackList: [] as Array<any>,
    ratingPopup: false as boolean,
    rules: {
      required: [
        (v: string): boolean | string =>
          !!v || "Поле обязательно для заполнения!"
      ]
    }
  }),

  computed: {
    computedRules(): any {
      return {
        minNumber: this.min,
        samePassword: this.samePassword
      };
    },
    rating(): string {
      const sum = this.feedbackList.reduce(
        (accumulator: number, item: any) => accumulator + item.stars,
        0
      );

      return (sum / this.feedbackList.length).toFixed(1);
    }
  },

  watch: {
    "$route.query"() {
      this.setFilters(this.$route.query);
      this.applyFilters();
    }
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const params = {
        filter: to.query,
        page: {
          number: to.params.page,
          size: 20
        }
      };
      const response = await API.users().getData(params);
      const roles = await API.roles().getList();
      next(vm => {
        vm.selects.roles = roles;
        vm.setFilters(params.filter);
        vm.setServerResponse(response);
      });
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }
  },

  methods: {
    getPasswordType(key: string): string {
      if (this.shownPassword[key]) {
        return "text";
      }
      return "password";
    },
    min(number: number): any {
      return (v: string): boolean | string =>
        !v || Number(v) >= number || `Минимальное значение ${number}`;
    },
    samePassword(): any {
      return (v: string): boolean | string =>
        v === this.password.new || `Пароль не совпадает!`;
    },
    async loadData(): Promise<void> {
      this.loading = true;
      try {
        const response = await this.$API.users().getData({
          filter: this.parseArrayParams(this.filter),
          page: this.pagination,
          sort: this.getSortParams()
        });

        this.setServerResponse(response);
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    setServerResponse(response: any) {
      this.headers = [
        ...response.headers,
        {
          text: "",
          value: "actions",
          width: "14em",
          align: "center",
          sortable: false
        }
      ];
      this.items = response.items.data;
      this.totalItems = response.items.total;
    },
    setFilters(filter: Dictionary<string | (string | null)[]>): void {
      const newFilters = this.getAvailableFilters(filter);

      if (newFilters.hasOwnProperty("position_name")) {
        newFilters.position_name = Number(newFilters.position_name);
      }

      if (Object.keys(newFilters).length) {
        this.showFilters = true;
        this.showSearch = true;
      }

      this.filter = newFilters;
      this.changeActiveFilters();
    },
    toggleSearch(): void {
      this.showSearch = !this.showSearch;

      if (this.showSearch) {
        this.$nextTick(() => {
          (this.$refs.search as any).focus();
        });
      }
    },
    search() {
      if (this.debounced) {
        this.debounced.cancel();
      }
      this.debounced = this.debounce(500, this.applyFilters);
      this.debounced();
    },
    async deleteRow(id: number): Promise<any> {
      try {
        await this.$API.users().delete(id);
        await this.$store.dispatch(
          "alert/showSuccess",
          this.$t("global_alert.successful_removal")
        );
        await this.loadData();
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async changePassword(): Promise<void> {
      try {
        if ((this.$refs.passwordForm as any).validate()) {
          await this.$API.users().changeUserPassword(this.selectedUser.id, {
            password: this.password.new,
            password_confirmation: this.password.confirm
          });

          this.changePasswordDialog = false;
          this.selectedUser = {};
          await this.$store.dispatch(
            "alert/showSuccess",
            "Пароль успешно изменен"
          );
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async loadUserFeedback(user: any): Promise<void> {
      try {
        // const response = await this.$API.user().getFeedbackList({
        //   manager_id: user.id,
        // });

        // if (!response.length) {
        //   this.closeFeedbackDialog();
        //   await this.loadData();
        //   return;
        // }
        //
        // this.feedbackList = response;
        this.selectedUser = user;

        this.feedbackDialog = true;
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    closeFeedbackDialog(): void {
      this.feedbackDialog = false;
      this.feedbackList = [];
      this.selectedUser = {};
    },
    toggleChangePasswordDialog(item: any): void {
      this.selectedUser = { ...item };
      this.changePasswordDialog = true;
    },
    closeChangePasswordDialog(): void {
      this.selectedUser = {};
      this.password = {};
      this.changePasswordDialog = false;
    },
    showPassword(key: string): void {
      this.shownPassword[key] = true;
    },
    hidePassword(key: string): void {
      this.shownPassword[key] = false;
    },
    async deleteFeedback(id: number): Promise<void> {
      try {
        // await this.$API.user().deleteFeedback({
        //   id,
        // });
        await this.$store.dispatch(
          "alert/showSuccess",
          "Отзыв успешно удален!"
        );

        await this.loadUserFeedback(this.selectedUser);
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    togglePasswordType(key: string): void {
      this.shownPassword[key] = !this.shownPassword[key];
    },
    starsAmount(stars: number): number {
      return this.feedbackList.filter((item: any) => item.stars === stars)
        .length;
    }
  }
});
